import {message} from "antd";
import auth from "../../utils/auth";
import {prefix} from "./prefix";


export type Init = {
  data?: object
} & RequestInit

const request = (input: RequestInfo | URL, init?: Init ) => {
  const pathReg = /^http(s?):\/\/.*$/
  if (typeof(input) == "string") {
    if (!input.match(pathReg)) {
      input = prefix + input;
    }
  }
  init = {
    method: 'get',
    ...(init ?? {}),
    headers: {
      ...(init?.headers ?? {}),
      ...(auth.getToken() ? {"Authorization": `${auth.getToken() ?? ""}`} : {}),
    }
  }
  if (!!init.data) {
    const formData = new FormData()
    Object.keys(init.data).forEach(key => {
      const value = init.data[key]
      if (Array.isArray(value)) {
        console.log(value)
        value.forEach(v => {
          formData.append(key, v)
        })
      } else {
        formData.set(key, value === undefined ? '' : value);
      }
    })
    init.body = formData
  }
  return new Promise<Response>((resolve, reject) => {
    fetch(input, init).then(resolve).catch(e => {
      message.error("网络错误")
      console.error(e)
      reject(e)
    });
  });
}

export default request

