import {Dispatch} from "react";
import request from "../utils/request";
import {navigate} from "gatsby";

import * as queryString from 'query-string';

const Auth = {
  setToken(token: string) {
    if (typeof localStorage !== "undefined") {
      localStorage?.setItem("auth-token", token);
    }
  },
  getToken() {
    if (typeof localStorage !== "undefined") {
      return localStorage.getItem("auth-token");
    }
    return ""
  },
  clearToken() {
    if (typeof localStorage !== "undefined") {
      localStorage?.removeItem('auth-token');
    }
  },
  load(dispatch: Dispatch<any>) {
    const token = this.getToken()
    if (!token) {
      dispatch({
        type: 'loadedAccount',
      })
      return;
    }
    dispatch({
      type: 'loadingAccount',
    });
    return request("/account").then(rsp => {
      if (rsp.status == 200) {
        rsp.json().then(json => {
          dispatch({
            type: 'account',
            account: json.data,
          })
        });
      } else if (rsp.status == 401) {
        this.logout(dispatch)
      }
    }).catch(console.error)
  },
  logout(dispatch, redirect = false) {
    this.clearToken()
    dispatch({type: 'unauthorized'})
    if (redirect) {
      this.redirectLoginPage()
    }
  },
  authorize()
  {
    const token = this.getToken()
    if (!token) {
      this.redirectLoginPage()
    }
  },
  isCompleted(account) {
    return account.resumeCompletedAt != null &&
      account.caseCompletedAt != null &&
      account.introductionCompletedAt != null &&
      account.skillUpdatedAt != null
  },
  redirect()
  {
    const queries = queryString.parse(location.search)
    navigate(queries.redirect ? queries.redirect as string : '/')
  },
  redirectLoginPage() {
    if (typeof location !== 'undefined' && !location.pathname.match(/^\/sign.*/)) {
      const url = location.pathname + location.search
      navigate("/sign/in?redirect=" + encodeURIComponent(url));
    }
  },
}

export default Auth;
