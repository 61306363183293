/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'

import createStore from './src/state/createStore'

import "./src/styles/global.css"
import "./src/styles/modal.less"

import zh_CN from 'antd/lib/locale/zh_CN'
import "antd/dist/antd.css"
import {Provider} from "react-redux"
import auth from "./src/utils/auth"
import {ConfigProvider} from "antd"
// import { Script } from "gatsby"

// import zhCN from 'antd';

const store = createStore()

export const wrapPageElement = (params) => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={zh_CN}>
        {params.element}
      </ConfigProvider>
      {/*<Script src="https://s9.cnzz.com/z_stat.php?id=1281246884&web_id=1281246884" />*/}
    </Provider>
  )
}

export const onRouteUpdate = () => {
  auth.load(store.dispatch)
}

export const onClientEntry = () => {
  document.querySelector("html").style.overflowY = 'scroll'
}
