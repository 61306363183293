import {createStore} from 'redux';

const initState = {
  name: 'gitwork',
  account: null,
  loadingAccount: false,
  loadedAccount: false,
  value: 0,
  // resumeCompleted =>
}

function reducer(state, action) {
  switch (action.type) {
    case 'increment': {
      return {
        ...state,
        value: state.value + action.preload
      }
    }
    case 'loadingAccount': {
      return {
        ...state,
        loadedAccount: false,
        loadingAccount: true,
      }
    }
    case 'account': {
      return {
        ...state,
        account: action.account,
        loadingAccount: false,
        loadedAccount: true,
      }
    }
    case 'loadedAccount': {
      return {
        ...state,
        loadingAccount: false,
        loadedAccount: true,
      }
    }
    case 'unauthorized': {
      return {
        ...state,
        account: null,
        loadingAccount: false,
        loadedAccount: true,
      }
    }
  }
  return initState
}

export default preloadedState => {
  return createStore(reducer, preloadedState)
}
